import React from "react";
import PropTypes from "prop-types";

Footer.propTypes = {};
function Footer(props) {
  return (
    <div>
      <span></span>
    </div>
  );
}

export default Footer;
